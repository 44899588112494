import React from 'react';

import huset_fasada from './assets/huset_fasade400.jpg';

export const HeroParallaxImageMobile: React.FunctionComponent = () => {
  return (
    <>
      <figure className="hero-logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="169.957"
          height="99.297"
          viewBox="0 0 169.957 99.297"
        >
          <g id="Layer_1" data-name="Layer 1" transform="translate(0)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M5.649,160.6a5.733,5.733,0,0,1-2.126-.405,5.414,5.414,0,0,1-1.809-1.175A5.655,5.655,0,0,1,.469,157.15,6.26,6.26,0,0,1,0,154.695a6.6,6.6,0,0,1,.458-2.508,5.872,5.872,0,0,1,1.257-1.938A5.632,5.632,0,0,1,3.6,149.01a6.108,6.108,0,0,1,2.349-.44,6.067,6.067,0,0,1,1.662.211,6.458,6.458,0,0,1,1.421.587l.358-.552h.7l.076,4.111H9.425a9.243,9.243,0,0,0-.423-1.3,4.921,4.921,0,0,0-.658-1.174,3.036,3.036,0,0,0-1-.863,2.678,2.678,0,0,0-1.321-.311,3.6,3.6,0,0,0-1.58.346,3.483,3.483,0,0,0-1.263,1.034,4.827,4.827,0,0,0-.8,1.674A8.222,8.222,0,0,0,2.1,154.6a6.548,6.548,0,0,0,.3,2.026,5.226,5.226,0,0,0,.863,1.6A4.187,4.187,0,0,0,4.586,159.3a3.735,3.735,0,0,0,1.709.388,4.059,4.059,0,0,0,1.31-.206,3.687,3.687,0,0,0,1.034-.552,4.379,4.379,0,0,0,.758-.816,4.587,4.587,0,0,0,.54-.987l.658.288a5.062,5.062,0,0,1-2,2.449,5.679,5.679,0,0,1-2.948.734Z"
              transform="translate(0.001 -61.319)"
              fill="#fff"
            />
            <path
              id="Path_33"
              data-name="Path 33"
              d="M34.662,160.375H29.494v-.587a5.922,5.922,0,0,0,1.133-.164c.3-.076.452-.176.452-.3a1.11,1.11,0,0,0,0-.176.792.792,0,0,0-.053-.188l-1.034-2.584H25.612c-.164.382-.3.711-.4.992s-.194.54-.276.781a4.953,4.953,0,0,0-.159.587,1.944,1.944,0,0,0-.047.352c0,.217.188.388.552.505a4.959,4.959,0,0,0,1.245.211v.587h-4.7v-.587a3.915,3.915,0,0,0,.587-.094,1.914,1.914,0,0,0,.587-.206,2.2,2.2,0,0,0,.546-.458,2.937,2.937,0,0,0,.376-.664c.623-1.445,1.315-3.066,2.067-4.868l2.014-4.816h.7l4.146,9.937a1.892,1.892,0,0,0,.3.511,1.986,1.986,0,0,0,.464.382,1.92,1.92,0,0,0,.523.188,2.879,2.879,0,0,0,.546.088Zm-5-4.7L27.8,151.225,25.947,155.7Z"
              transform="translate(-9.009 -61.373)"
              fill="#fff"
            />
            <path
              id="Path_34"
              data-name="Path 34"
              d="M58.025,151.845h-.658a4.111,4.111,0,0,0-.769-1.374,1.9,1.9,0,0,0-1.116-.8c-.182,0-.393-.035-.646-.047s-.529,0-.846,0H51.905v4.628h1.433a3.054,3.054,0,0,0,1.01-.129,1.586,1.586,0,0,0,.617-.382,1.567,1.567,0,0,0,.317-.558,2.989,2.989,0,0,0,.194-.8h.652V156.9h-.652a3.729,3.729,0,0,0-.182-.81,2.05,2.05,0,0,0-.329-.658,1.327,1.327,0,0,0-.664-.411,3.557,3.557,0,0,0-.963-.112H51.905v3.964a1.022,1.022,0,0,0,.112.482.816.816,0,0,0,.405.335,2.827,2.827,0,0,0,.587.141,5.323,5.323,0,0,0,.664.088v.587h-5.4v-.587l.7-.053a2.05,2.05,0,0,0,.587-.094.734.734,0,0,0,.382-.311,1.028,1.028,0,0,0,.117-.5v-8.328a1.092,1.092,0,0,0-.094-.476.7.7,0,0,0-.405-.335,3.086,3.086,0,0,0-.634-.188,4.641,4.641,0,0,0-.634-.1v-.587h9.737Z"
              transform="translate(-19.922 -61.476)"
              fill="#fff"
            />
            <path
              id="Path_35"
              data-name="Path 35"
              d="M81.145,154.52l-.235,3.394H70.58v-.587a4.694,4.694,0,0,0,.7-.07,2.788,2.788,0,0,0,.587-.106.769.769,0,0,0,.388-.317.981.981,0,0,0,.112-.5v-8.263a1.175,1.175,0,0,0-.094-.476.7.7,0,0,0-.405-.335,3.083,3.083,0,0,0-.634-.188,4.69,4.69,0,0,0-.64-.1v-.587h9.625v2.778h-.675a3.553,3.553,0,0,0-.758-1.31,1.891,1.891,0,0,0-1.11-.763c-.176,0-.393-.035-.646-.047s-.534,0-.857,0H74.221v4.581h1.4a3.107,3.107,0,0,0,.987-.117,1.409,1.409,0,0,0,.546-.393,2.031,2.031,0,0,0,.329-.628,3.922,3.922,0,0,0,.182-.746h.652v4.51h-.652a3.524,3.524,0,0,0-.194-.822,1.991,1.991,0,0,0-.317-.628,1.263,1.263,0,0,0-.587-.411,3.524,3.524,0,0,0-.916-.1H74.221v3.453a3.336,3.336,0,0,0,.076.816.746.746,0,0,0,.311.452,1.321,1.321,0,0,0,.623.2,10.58,10.58,0,0,0,1.122.047h.758a7.586,7.586,0,0,0,.8-.035,4.21,4.21,0,0,0,.687-.117,1.01,1.01,0,0,0,.505-.229,5.039,5.039,0,0,0,.846-1.175,7.3,7.3,0,0,0,.587-1.175Zm-3.67-10.2-1.175.54-1.838.822-.27-.317c.534-.47.963-.857,1.3-1.175s.664-.587.993-.934a2.261,2.261,0,0,1,.511-.4,1.133,1.133,0,0,1,.5-.117.91.91,0,0,1,.587.188.587.587,0,0,1,.235.464.7.7,0,0,1-.229.552A2.349,2.349,0,0,1,77.475,144.319Z"
              transform="translate(-29.13 -58.912)"
              fill="#fff"
            />
            <path
              id="Path_36"
              data-name="Path 36"
              d="M117.865,153.541a5.083,5.083,0,0,0-1.045.188,1.6,1.6,0,0,0-.8.476,2.5,2.5,0,0,0-.376.587c-.123.258-.27.558-.446.9s-.352.687-.534,1.022a6.1,6.1,0,0,1-.7,1,10.709,10.709,0,0,0,.857.852,5.165,5.165,0,0,0,.863.658,3.922,3.922,0,0,0,1,.317,6.906,6.906,0,0,0,1.051.147v.587h-3.6l-1.3-1.38a8.7,8.7,0,0,1-.664.587,4.886,4.886,0,0,1-.781.54,5.058,5.058,0,0,1-.916.382,3.754,3.754,0,0,1-1.086.141,4.852,4.852,0,0,1-1.286-.176,3.64,3.64,0,0,1-1.175-.587,2.8,2.8,0,0,1-1.139-2.349,3.107,3.107,0,0,1,.587-1.821,6.894,6.894,0,0,1,2.144-1.762,16.008,16.008,0,0,1-1.1-1.5,2.613,2.613,0,0,1-.393-1.421,2.22,2.22,0,0,1,.241-1.022,2.438,2.438,0,0,1,.681-.816,3.019,3.019,0,0,1,.981-.5,4.009,4.009,0,0,1,1.175-.176,3.482,3.482,0,0,1,1.233.206,2.52,2.52,0,0,1,.863.54,2.15,2.15,0,0,1,.464.716,2.1,2.1,0,0,1,.164.81,2.349,2.349,0,0,1-.652,1.674,5.962,5.962,0,0,1-1.8,1.263c.552.687,1.086,1.315,1.615,1.879s1.028,1.122,1.515,1.668a9.448,9.448,0,0,0,.9-1.633,3.524,3.524,0,0,0,.323-1.351c0-.176-.135-.311-.405-.411a6.986,6.986,0,0,0-1.128-.288v-.587h4.7Zm-5.5,4.868q-.816-.957-1.838-2.1t-1.633-1.926a2.613,2.613,0,0,0-1,1.063,3.241,3.241,0,0,0-.294,1.4,2.543,2.543,0,0,0,.681,1.826,2.349,2.349,0,0,0,1.815.728,3.36,3.36,0,0,0,1.257-.253,2.572,2.572,0,0,0,1.016-.746Zm-.681-7.505a1.891,1.891,0,0,0-.393-1.274,1.433,1.433,0,0,0-1.122-.44,1.392,1.392,0,0,0-.681.153,1.668,1.668,0,0,0-.464.393,1.433,1.433,0,0,0-.253.487,1.579,1.579,0,0,0-.082.458,2.349,2.349,0,0,0,.317,1.133,9.989,9.989,0,0,0,.981,1.386,3.665,3.665,0,0,0,1.3-1.022,2.108,2.108,0,0,0,.382-1.274Z"
              transform="translate(-43.657 -61.257)"
              fill="#fff"
            />
            <path
              id="Path_37"
              data-name="Path 37"
              d="M157.88,160.462h-5.5v-.587a3.924,3.924,0,0,0,.769-.059,2.043,2.043,0,0,0,.646-.135,1.25,1.25,0,0,0,.4-.352.939.939,0,0,0,.123-.5V150.46h-.129l-4.246,9.813h-.435l-4.04-9.984h-.117v6.871a6.549,6.549,0,0,0,.135,1.521,1.4,1.4,0,0,0,.417.746,1.991,1.991,0,0,0,.8.323,5.11,5.11,0,0,0,.787.164v.587H142.47v-.587a7.437,7.437,0,0,0,.828-.123,1.656,1.656,0,0,0,.669-.264,1.175,1.175,0,0,0,.423-.7,7.576,7.576,0,0,0,.112-1.574v-5.943a2.238,2.238,0,0,0-.129-.834,1.3,1.3,0,0,0-.376-.529,1.762,1.762,0,0,0-.67-.323,3.606,3.606,0,0,0-.775-.123v-.587h4.24l3.559,8.645,3.042-7.229a7.336,7.336,0,0,0,.288-.834,4.973,4.973,0,0,0,.129-.587h4.052v.587a5.366,5.366,0,0,0-.628.07c-.253.047-.435.088-.552.123a.634.634,0,0,0-.405.329,1.175,1.175,0,0,0-.106.493v8.3a.98.98,0,0,0,.106.476.858.858,0,0,0,.405.341,1.968,1.968,0,0,0,.587.153,4.931,4.931,0,0,0,.64.076Z"
              transform="translate(-58.801 -61.459)"
              fill="#fff"
            />
            <path
              id="Path_38"
              data-name="Path 38"
              d="M185.5,150.119a5.62,5.62,0,0,1,1.292,1.92,6.553,6.553,0,0,1,.464,2.49,6.313,6.313,0,0,1-.476,2.49,5.55,5.55,0,0,1-1.315,1.909,5.761,5.761,0,0,1-1.891,1.175,6.254,6.254,0,0,1-2.3.423,6.155,6.155,0,0,1-2.4-.452,5.562,5.562,0,0,1-3.13-3.165,6.4,6.4,0,0,1-.452-2.4,6.26,6.26,0,0,1,.476-2.508,5.7,5.7,0,0,1,3.218-3.118,6.46,6.46,0,0,1,4.616,0A5.644,5.644,0,0,1,185.5,150.119Zm-1.3,8.169a5.231,5.231,0,0,0,.716-1.674,9.683,9.683,0,0,0,0-4.264,5.086,5.086,0,0,0-.734-1.685,3.271,3.271,0,0,0-1.21-1.075,3.471,3.471,0,0,0-1.662-.388,3.4,3.4,0,0,0-1.762.446,3.63,3.63,0,0,0-1.175,1.175,5.358,5.358,0,0,0-.652,1.674,9.633,9.633,0,0,0,0,4.111,5.251,5.251,0,0,0,.717,1.674,3.277,3.277,0,0,0,2.936,1.515,3.523,3.523,0,0,0,1.691-.405A3.294,3.294,0,0,0,184.2,158.288Z"
              transform="translate(-72.35 -61.271)"
              fill="#fff"
            />
            <path
              id="Path_39"
              data-name="Path 39"
              d="M211.958,152.107h-.587a3.907,3.907,0,0,0-.282-.693,6.058,6.058,0,0,0-.464-.758,3.7,3.7,0,0,0-.587-.658,1.174,1.174,0,0,0-.587-.317q-.276-.035-.687-.053h-1.274v9.208a.993.993,0,0,0,.123.493.811.811,0,0,0,.429.347,6.372,6.372,0,0,0,.664.153,5.331,5.331,0,0,0,.787.106v.587h-5.873v-.587l.752-.065a3.037,3.037,0,0,0,.7-.123.811.811,0,0,0,.423-.305.881.881,0,0,0,.135-.529v-9.3h-1.18a5.479,5.479,0,0,0-.775.053,1.175,1.175,0,0,0-.587.317,4.484,4.484,0,0,0-.587.658,5.639,5.639,0,0,0-.464.775,3.8,3.8,0,0,0-.276.675h-.587V149h10.841Z"
              transform="translate(-83.028 -61.496)"
              fill="#fff"
            />
            <path
              id="Path_40"
              data-name="Path 40"
              d="M235.6,157.084l-.235,3.394h-10.3v-.587a6.946,6.946,0,0,0,.7-.071,2.788,2.788,0,0,0,.587-.106.811.811,0,0,0,.393-.317,1.045,1.045,0,0,0,.106-.5v-8.263a1.091,1.091,0,0,0-.094-.476.7.7,0,0,0-.405-.335,3.085,3.085,0,0,0-.634-.188,4.641,4.641,0,0,0-.634-.1v-.587H234.7v2.778h-.658a3.576,3.576,0,0,0-.763-1.31,1.9,1.9,0,0,0-1.1-.763c-.182,0-.4-.035-.652-.047s-.534,0-.857,0h-1.956v4.581h1.4a3.082,3.082,0,0,0,.987-.117,1.41,1.41,0,0,0,.546-.393,2.032,2.032,0,0,0,.329-.628,3.922,3.922,0,0,0,.182-.746h.658v4.51h-.658a3.522,3.522,0,0,0-.188-.822,2.108,2.108,0,0,0-.323-.628,1.262,1.262,0,0,0-.617-.411,3.525,3.525,0,0,0-.916-.1h-1.4v3.453a3.028,3.028,0,0,0,.082.816.74.74,0,0,0,.305.452,1.345,1.345,0,0,0,.623.2,10.579,10.579,0,0,0,1.122.047h.763a7.636,7.636,0,0,0,.8-.035,4.434,4.434,0,0,0,.687-.117.987.987,0,0,0,.5-.229,5.04,5.04,0,0,0,.846-1.175,7.292,7.292,0,0,0,.587-1.175Z"
              transform="translate(-92.888 -61.476)"
              fill="#fff"
            />
            <path
              id="Path_41"
              data-name="Path 41"
              d="M259.173,157.063l-.264,3.424h-9.719V159.9l.587-.059a1.974,1.974,0,0,0,.558-.106.775.775,0,0,0,.382-.305,1.057,1.057,0,0,0,.117-.523v-8.222a1.174,1.174,0,0,0-.1-.487.781.781,0,0,0-.4-.341,2.936,2.936,0,0,0-.587-.188,3.318,3.318,0,0,0-.587-.112v-.587h5.25v.587a4.7,4.7,0,0,0-.634.082,5.386,5.386,0,0,0-.587.129.646.646,0,0,0-.411.335,1.175,1.175,0,0,0-.106.5v7.74a5.025,5.025,0,0,0,.053.828.728.728,0,0,0,.27.458,1.239,1.239,0,0,0,.587.194,10.982,10.982,0,0,0,1.116.041h.675a4.8,4.8,0,0,0,.67-.065,4.424,4.424,0,0,0,.587-.135.934.934,0,0,0,.382-.217,7.148,7.148,0,0,0,.84-1.133,8,8,0,0,0,.658-1.227Z"
              transform="translate(-102.835 -61.484)"
              fill="#fff"
            />
            <path
              id="Path_42"
              data-name="Path 42"
              d="M281.623,157.063l-.258,3.424h-9.725V159.9l.587-.059a1.974,1.974,0,0,0,.558-.106.746.746,0,0,0,.382-.305,1.057,1.057,0,0,0,.117-.523v-8.222a1.175,1.175,0,0,0-.1-.487.757.757,0,0,0-.4-.341,2.977,2.977,0,0,0-.587-.188,3.462,3.462,0,0,0-.587-.112v-.587h5.25v.587a4.944,4.944,0,0,0-.634.082,5.374,5.374,0,0,0-.587.129.622.622,0,0,0-.405.335,1.11,1.11,0,0,0-.112.5v7.74a4.365,4.365,0,0,0,.059.828.7.7,0,0,0,.264.458,1.239,1.239,0,0,0,.587.194,11.1,11.1,0,0,0,1.122.041h.675a4.8,4.8,0,0,0,.664-.065,4.426,4.426,0,0,0,.587-.135.892.892,0,0,0,.382-.217,6.8,6.8,0,0,0,.84-1.133,8.144,8.144,0,0,0,.664-1.227Z"
              transform="translate(-112.1 -61.484)"
              fill="#fff"
            />
            <path
              id="Path_43"
              data-name="Path 43"
              d="M36.639,91.333H22.409V89.618l1.68-.141a5.015,5.015,0,0,0,1.509-.294,2.049,2.049,0,0,0,1.063-.881A3.083,3.083,0,0,0,27,86.77v-12.2H10.887V86.506a3.206,3.206,0,0,0,.282,1.421,2.349,2.349,0,0,0,1.1,1.016,5.783,5.783,0,0,0,1.456.435,10.2,10.2,0,0,0,1.656.241v1.715H1.15V89.618l1.674-.141a4.975,4.975,0,0,0,1.509-.294A2.026,2.026,0,0,0,5.4,88.3,3.024,3.024,0,0,0,5.76,86.77V62.034a3.224,3.224,0,0,0-.311-1.415,2.2,2.2,0,0,0-1.092-1,8.727,8.727,0,0,0-1.58-.587,9.113,9.113,0,0,0-1.627-.247V57.06H15.38v1.727A10.993,10.993,0,0,0,13.724,59a10.487,10.487,0,0,0-1.456.364,1.685,1.685,0,0,0-1.1.963,3.788,3.788,0,0,0-.282,1.474v10.63H27v-10.4a3.148,3.148,0,0,0-.317-1.415,2.167,2.167,0,0,0-1.086-1,8.892,8.892,0,0,0-1.58-.587,9.114,9.114,0,0,0-1.609-.247V57.06h14.23v1.727a14,14,0,0,0-3.118.587,1.715,1.715,0,0,0-1.1.963,3.9,3.9,0,0,0-.276,1.474V86.506a3.3,3.3,0,0,0,.276,1.421,2.4,2.4,0,0,0,1.1,1.016,5.937,5.937,0,0,0,1.462.435,10.028,10.028,0,0,0,1.656.241Z"
              transform="translate(-0.474 -23.55)"
              fill="#fff"
            />
            <path
              id="Path_44"
              data-name="Path 44"
              d="M104.062,58.787a13.41,13.41,0,0,0-1.979.288,6.067,6.067,0,0,0-2.126.8,3.37,3.37,0,0,0-1.133,2.3,24.08,24.08,0,0,0-.317,4.246V81.308a9.907,9.907,0,0,1-1.175,4.957,10.406,10.406,0,0,1-3.113,3.453A13.249,13.249,0,0,1,90.2,91.592a15.71,15.71,0,0,1-4.046.587,17.454,17.454,0,0,1-5.509-.8A12.333,12.333,0,0,1,76.56,89.16a9.109,9.109,0,0,1-2.437-3.154A8.721,8.721,0,0,1,73.3,82.3V62.034a3.271,3.271,0,0,0-.264-1.415,2.66,2.66,0,0,0-1.128-1.045,5.414,5.414,0,0,0-1.545-.546,11.97,11.97,0,0,0-1.545-.241V57.06H83.1v1.727a11.908,11.908,0,0,0-1.7.217,11.392,11.392,0,0,0-1.509.358,1.762,1.762,0,0,0-1.1.969A3.888,3.888,0,0,0,78.5,61.8V80.715A14.387,14.387,0,0,0,78.81,83.6a7.575,7.575,0,0,0,1.175,2.813,7.129,7.129,0,0,0,2.561,2.173,9.7,9.7,0,0,0,4.446.846,10.442,10.442,0,0,0,4.457-.846,7.447,7.447,0,0,0,2.837-2.22,8.286,8.286,0,0,0,1.4-2.731,10.441,10.441,0,0,0,.411-2.872V66.715a20.306,20.306,0,0,0-.388-4.381A3.673,3.673,0,0,0,94.578,60.1a6.4,6.4,0,0,0-2.308-.94,14.148,14.148,0,0,0-2.2-.376V57.06H104.08Z"
              transform="translate(-28.403 -23.55)"
              fill="#fff"
            />
            <path
              id="Path_45"
              data-name="Path 45"
              d="M155.64,75.065a8,8,0,0,1,1.721,2.643,9.4,9.4,0,0,1,.552,3.347,9.273,9.273,0,0,1-3.594,7.488c-2.39,1.961-6.46,2.936-9.984,2.936a17.49,17.49,0,0,1-4.91-.716,19.786,19.786,0,0,1-4.24-1.727l-1.11,1.762h-2.067l-.358-11.745h2.091a24.955,24.955,0,0,0,1.509,3.923,13.508,13.508,0,0,0,2.29,3.271,10.237,10.237,0,0,0,3.124,2.3,9.884,9.884,0,0,0,4.17.822,14.465,14.465,0,0,0,4.2-.435,5.421,5.421,0,0,0,2.155-1.233,4.95,4.95,0,0,0,1.216-1.856,7.147,7.147,0,0,0,.4-2.478,6.542,6.542,0,0,0-1.239-3.829,7.635,7.635,0,0,0-3.694-2.7q-1.685-.623-3.847-1.38c-1.451-.505-2.7-.987-3.747-1.433a12.1,12.1,0,0,1-4.821-3.418,8.468,8.468,0,0,1-1.715-5.514,7.935,7.935,0,0,1,.852-3.647,9.614,9.614,0,0,1,2.425-3.024,11.246,11.246,0,0,1,3.524-1.967,18.17,18.17,0,0,1,5.4-.711,14.382,14.382,0,0,1,4.516.74,22.579,22.579,0,0,1,3.659,1.685l1.057-1.638h2.067l.206,11.375h-2.108c-.382-1.3-.8-2.555-1.28-3.747a13.2,13.2,0,0,0-1.85-3.236,8.334,8.334,0,0,0-2.713-2.232,8.175,8.175,0,0,0-3.923-.834,8.618,8.618,0,0,0-5.285,1.474,4.545,4.545,0,0,0-1.709,3.612A5.873,5.873,0,0,0,139.7,66.62a8.046,8.046,0,0,0,3.224,2.4q1.885.846,3.712,1.462t3.524,1.315a28.438,28.438,0,0,1,2.983,1.427A11.113,11.113,0,0,1,155.64,75.065Z"
              transform="translate(-54.335 -23.003)"
              fill="#fff"
            />
            <path
              id="Path_46"
              data-name="Path 46"
              d="M213.8,81.238l-.652,10.1H184.688V89.618a17.457,17.457,0,0,0,1.932-.206,6.538,6.538,0,0,0,1.574-.323,2.237,2.237,0,0,0,1.075-.94,3.17,3.17,0,0,0,.3-1.468V62.017A3.577,3.577,0,0,0,189.3,60.6a2.067,2.067,0,0,0-1.11-1,8.275,8.275,0,0,0-1.762-.587,12.921,12.921,0,0,0-1.762-.288V57.06H211.2v8.257h-1.815a11.159,11.159,0,0,0-2.091-3.9,5.142,5.142,0,0,0-3.054-2.255c-.5-.065-1.1-.117-1.762-.147s-1.486-.047-2.349-.047h-5.385V72.593h3.864a7.681,7.681,0,0,0,2.719-.352,3.717,3.717,0,0,0,1.509-1.175,6.148,6.148,0,0,0,.9-1.868,11.708,11.708,0,0,0,.5-2.214h1.768V80.416H204.2a10.79,10.79,0,0,0-.523-2.449,6.286,6.286,0,0,0-.881-1.873,3.489,3.489,0,0,0-1.7-1.233,8.933,8.933,0,0,0-2.525-.288H194.7V84.844a10.619,10.619,0,0,0,.217,2.437,2.285,2.285,0,0,0,.846,1.351,3.623,3.623,0,0,0,1.715.587,27.6,27.6,0,0,0,3.095.129h2.1a21.013,21.013,0,0,0,2.2-.094,12.876,12.876,0,0,0,1.9-.352,2.9,2.9,0,0,0,1.386-.687,15.351,15.351,0,0,0,2.349-3.524,22.947,22.947,0,0,0,1.556-3.465Z"
              transform="translate(-76.218 -23.55)"
              fill="#fff"
            />
            <path
              id="Path_47"
              data-name="Path 47"
              d="M268,66.357h-1.668a11.344,11.344,0,0,0-.787-2.061,18.7,18.7,0,0,0-1.268-2.261,11.746,11.746,0,0,0-1.586-1.944,3.119,3.119,0,0,0-1.662-.951c-.5-.07-1.128-.117-1.885-.147s-1.462-.047-2.108-.047h-1.427V86.388a3.118,3.118,0,0,0,.341,1.462,2.243,2.243,0,0,0,1.175,1.028,14.557,14.557,0,0,0,1.826.458,12.726,12.726,0,0,0,2.161.311v1.715H244.932V89.618c.452,0,1.139-.094,2.061-.188a8.263,8.263,0,0,0,1.92-.364,2.349,2.349,0,0,0,1.175-.9,2.972,2.972,0,0,0,.376-1.586v-27.6h-1.4c-.5,0-1.116,0-1.862.035s-1.445.076-2.126.159a2.936,2.936,0,0,0-1.65.951,12.756,12.756,0,0,0-1.586,1.944,16.245,16.245,0,0,0-1.28,2.308,13.515,13.515,0,0,0-.746,1.985H238.12v-9.3H268Z"
              transform="translate(-98.278 -23.55)"
              fill="#fff"
            />
            <path
              id="Path_48"
              data-name="Path 48"
              d="M170.44,24.025h-65.6V13.272L86.242,3.53,67.655,13.272V24.025H1.17V20.9H64.525v-9.52L86.242,0l21.723,11.381V20.9H170.44Z"
              transform="translate(-0.482)"
              fill="#fff"
            />
            <rect
              id="Rectangle_17"
              data-name="Rectangle 17"
              width="169.393"
              height="2.854"
              transform="translate(0.535 76.674)"
              fill="#fff"
            />
          </g>
        </svg>
      </figure>
      <div className="hero-img__overlay"></div>
      <img src={huset_fasada} alt="frontent av huset cafe og motell" />
    </>
  );
};
